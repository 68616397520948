.create-product-page {
  .add-product-form {
    padding: 20px;

    .ant-form-item {
      margin-bottom: 0;
    }

    .ant-form-item-label {
      padding: 0;
    }

    .section {
      padding: 20px;
      border: 1px solid #e6e6e6;
      background: #fff;
      border-radius: 5px;
      min-height: 400px;
    }

    .header {
      height: 64px;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }

    .form-title {
      font-size: 18px;
      margin-bottom: 0px;
      font-weight: 700;
    }

    .parent-row {
    }
  }
}
