/* CustomTree.css */

/* Custom checkbox style */
.custom-tree .ant-tree-checkbox-wrapper {
  position: relative;
  padding-left: 34px; /* Adjust as needed */
}

.custom-tree .ant-tree-checkbox-inner {
  border-radius: 20px; /* Create a circular appearance */
  width: 40px; /* Set the size of the "switch" */
  height: 20px; /* Set the size of the "switch" */
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}

.custom-tree .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  justify-content: flex-end;
}



/* Add the round "ticks" */
.custom-tree .ant-tree-checkbox-checked .ant-tree-checkbox-inner::before {
  content: '';
  position: absolute;
  left: 4px; /* Adjust as needed */
  top: 50%;
  transform: translateY(-50%);
  width: 12px; /* Tick size */
  height: 12px; /* Tick size */
  border-radius: 50%;
  background-color: #fff; /* Tick color */
  transition: left 0.3s;
}

/* Tick position when checked */
.custom-tree .ant-tree-checkbox-checked .ant-tree-checkbox-inner::before {
  left: calc(100% - 16px); /* Move tick to the right when checked */
}

/* Change background color of unchecked checkboxes */
.custom-tree .ant-tree-checkbox-inner::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%; /* Full width of the checkbox */
  height: 100%; /* Full height of the checkbox */
  background-color: transparent; /* Transparent background for unchecked */
  border-radius: 20px; /* Match the checkbox's border radius */
}

/* Change background color of unchecked checkbox wrapper */
.custom-tree .ant-tree-checkbox-wrapper:not(.ant-tree-checkbox-checked):not(:hover) .ant-tree-checkbox-inner {
  background-color: #ddd; /* Change background color when not checked or hovered */
}

/* Adjust styles when hovering */
.custom-tree .ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner {
  background-color: #ddd; /* Change background color on hover */
}

/* Adjust styles when active */
.custom-tree .ant-tree-checkbox-wrapper:active .ant-tree-checkbox-inner {
  background-color: #ccc; /* Change background color when active */
}
