.ant-modal-wrap {
  z-index: 1010 !important;
}

.section {
  @media (max-width: 991px) {
    min-width: 46% !important;
    min-width: 46% !important;
  }

  @media (max-width: 768px) {
    min-width: 95% !important;
    min-width: 95% !important;
  }
  @media (max-width: 576px) {
    min-width: 100% !important;
    max-width: 100% !important;
  }

  .left {
    width: 80%;
    display: flex;
  }

  .add-item,
  .update-section {
    position: absolute;
    padding: 16px;
    padding-top: 10px;
    top: 0;
    left: 0;
    min-height: 150px;
    background-color: #fff;
    width: 90%;
    margin-left: 5%;
    margin-top: 10px;
    border-radius: 10px;
    box-shadow: 0px 10px 20px rgb(0 0 0 / 20%);
    z-index: 1000;

    .header {
      margin-bottom: 0px;
      display: flex;
      height: 30px;

      .title {
        font-size: 16px;
        margin-bottom: 0;
      }
    }
  }

  .close-x {
    position: absolute;
    right: 0;
    top: 0;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    margin: 7px;
    cursor: pointer;
    border-radius: 50%;
    color: gray;

    &:hover {
      color: #e54242;
    }
  }

  &-header {
    font-weight: bold;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .sort-order {
      font-size: 14px;
      min-width: 25px;
      max-width: 40px;
      height: 25px;
      background: #363636;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      // box-shadow: 0px 1px 2px rgb(0 0 0 / 20%);
      border-radius: 5px;
    }

    .name {
      font-size: 18px;
      text-transform: capitalize;
      padding-right: 15px;
      margin-bottom: 0;
    }

    .hubs {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      flex-direction: row;
      &-item {
        font-size: 14px;
        color: #fff;
        background: #000000b5;
        padding: 3px 10px;
        margin-right: 10px;
        border-radius: 5px;
        letter-spacing: 1px;
        margin-bottom: 5px;
      }
    }

    .more-icon {
      font-size: 24px;
      min-width: 35px;
      height: 35px;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      cursor: pointer;
      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
      color: green;
      &:hover {
        box-shadow: 0 11px 32px rgb(0 0 0 / 25%), 0 4px 15px rgb(0 0 0 / 22%);
        // box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
      }
    }
  }

  .delete-item-droppable {
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    // margin-left: 5%;
    // margin-top: 5px;
    display: none;
    align-items: center;
    justify-content: center;
    background: #00000045;
    border-radius: 10px;

    &.open {
      display: flex;
      width: 100%;
      height: 100px;
    }

    &.update {
      background: #8e000045;
    }

    .body {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      border-radius: 100%;
      background-color: #fff;
      position: absolute;

      .icon {
        line-height: 70%;
        color: red;
      }
    }
  }

  .droppable {
    min-height: 100px;
    &-item {
      padding: 5px 0px;
      .body {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 18px;
        font-weight: 500;
        color: rgb(95, 95, 95);
        background-color: #f7f7f7;
        padding: 20px;
        border-radius: 5px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
        &:hover {
          box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
            0 10px 10px rgba(0, 0, 0, 0.22);
        }

        .hub {
          font-size: 14px;
          color: #747474;
        }
      }
    }
  }
}

.create-new-section-wrap {
  width: 100%;
  height: 40px;

  .create-new {
    display: flex;
    align-items: center;
    width: 150px;
    background: #f1f1f1;
    padding: 7px 16px;
    border-radius: 10px;
    width: 170px;
    justify-content: center;
    letter-spacing: 1px;
    transition: 0.2s ease-in;
    cursor: pointer;

    .title {
      margin-bottom: 0;
      font-size: 18px;
    }

    .icon {
      font-size: 16px;
      line-height: 70%;
      margin-left: 10px;
    }

    &:hover {
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      background: #00a10721 !important;
    }
  }
}
