.historyHeader {
    font-size:16px;
    font-weight: bold
}
.historyResturentId {
    font-size:12px;
    margin-bottom: 5px;
}
.itemHolder{
    margin-top: 30px;
}