.leaflet-control-container .leaflet-routing-container-hide {
    display: none;
}
path.leaflet-interactive.animate {
    /* make the line like ants */
    stroke-dasharray: 200;
    stroke-dashoffset: 1920;
    /* repeat animation */
    animation: dash 18s linear forwards infinite;
    
}

path.leaflet-interactive.animate2 {
    /* make the line like ants */
    stroke-dasharray: 0;
    stroke-dashoffset: 1920;
    /* repeat animation */
    stroke: rgba(192, 193, 193, 0.267);
    animation: dash 3s linear forwards infinite;
    
}

path.leaflet-interactive.animate:hover {
    /* make the line like ants */
    stroke-dasharray: 25;
    stroke-dashoffset: 1000;
    /* repeat animation */
    animation: dash 6s linear forwards infinite;
}

@keyframes dash {
    to {
        stroke-dashoffset: 0; /* Animate the offset to reveal the line */
    }
}
