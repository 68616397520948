:root {
  --light-green: #e5fbe5;
  --dark-green: green;
}

body {
  scroll-behavior: smooth;
}

.border {
  border: 2px solid #cdcdcd;
}

.ant-pagination-total-text {
  margin-right: auto;
  font-weight: 500;
}

.ant-table {
  overflow: hidden;
  box-shadow: 0px 1px 2px grey;
  overflow: hidden;

  &-cell {
    font-weight: 500;
    letter-spacing: 0.5px;
  }
}

.customScroll {
  overflow-y: auto;
}

.customScroll::-webkit-scrollbar {
  background: #cccccc;
  width: 6px;
}

.customScroll::-webkit-scrollbar-track {
  background: #fff;
}

.customScroll::-webkit-scrollbar-thumb {
  background: #adadad;
  border-radius: 7px;
}

.scroll-x {
  overflow-x: auto;
  &::-webkit-scrollbar {
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    background: #fff;
  }
  &::-webkit-scrollbar-thumb {
    background: #adadad;
    border-radius: 7px;
  }
}

.ant-menu-item-selected,
.ant-menu-submenu-active {
  .ant-menu-submenu-title {
    font-weight: 500;
    color: var(--dark-green);
    background-color: var(--light-green);

    svg {
      color: var(--dark-green);
    }

    &:hover {
      color: var(--dark-green) !important;
      background-color: var(--light-green);
    }
  }

  .ant-menu-submenu-arrow {
    color: var(--dark-green);
    &:hover {
      color: var(--dark-green);
    }
  }
}

.table-image {
  position: relative;
  width: 100px;
  height: 75px;
  border: 2px solid #cdcdcd;
  overflow: hidden;
  cursor: pointer;
  background-color: #adadad;

  img {
    width: 100%;
    height: 100%;
  }
  .icon {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    .ex {
      top: 0;
      left: 0;
      font-size: 30px;
    }
    color: #fff;
  }

  &:hover {
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.flex-between {
  display: flex;
  justify-content: space-between;
}
.flex-around {
  display: flex;
  justify-content: space-around;
}
.flex-center {
  display: flex;
  justify-content: space-center;
}

.truncate {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
